import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import SEO from '../components/SEO'
import FullWidthWrapper from '../components/FullWidthWrapper'
import BigHeading from '../components/BigHeading'
import Paragraph from '../components/Paragraph'
import FlexBox from '../components/FlexBox'
import AngledDivider from '../components/AngledDivider'
import ParallaxWrapper from '../components/ParallaxWrapper'
import ContactSection from '../components/ContactSection'
import WordArt from '../components/WordArt'
import ContentBlock from '../components/ContentBlock'
import StrategyIcon from '../graphics/strategy.svg'
import PlatformIcon from '../graphics/platform.svg'
import CommunicationIcon from '../graphics/communication.svg'

const includedServices = {
  strategy: [
    'Unique Value Proposition',
    'Market Analysis',
    'Audience Segmentation',
    'Advanced Demographic Research',
    'Search Engine Marketing',
    'Paid Advertising',
    'Pre-Production Elements',
    'Graphic Design',
    'Copywriting'
  ],
  platform: [
    'Search Engine Optimization',
    'Data Analytics',
    'Social Media Campaigns',
    'Mobile Applications',
    'Custom Websites with Advanced Backend Database',
    'E-Commerce Websites',
    'Business Insight - Dashboard Development',
    'Software & Hardware Development',
    'Graphic Design',
    'Copywriting'
  ],
  communication: [
    'Commercial/Brand Film Production',
    'Influencer Marketing',
    'Event Marketing',
    'A/B Testing',
    'Beacon Marketing',
    'Geofencing',
    'Graphic Design',
    'Copywriting',
    'Photography'
  ]
}

const packages = [
  {
    name: 'Strategy',
    description:
      'The strategy package lays the groundwork for all campaigns. This is where we develop a strategic marketing plan that answers who we’re targeting, where we’re targeting them and the message we’re communicating.',
    services: includedServices.strategy,
    icon: StrategyIcon,
    color: '#2c2c2c'
  },
  {
    name: 'Platform',
    description:
      'The platform package includes the bulk of our development capabilities. In the second stage of the branding process, we create sales funnels using your unique value proposition to generate conversions on a custom website or app.',
    services: includedServices.platform,
    icon: PlatformIcon,
    color: '#1b1b1b'
  },
  {
    name: 'Communication',
    description:
      'The communication package includes our digital media capabilities. From brand films to social video content, location advertising and paid sponsorships, this is how we communicate your brand message.',
    services: includedServices.communication,
    icon: CommunicationIcon,
    color: '#0a0a0a'
  }
]

const Branding = ({ data }) => (
  <React.Fragment>
    <SEO title="Branding" />
    <Hero image={data.heroImage} />
    {packages.map(_package => (
      <Package key={_package.name} {..._package} />
    ))}
    <ContactSection />
  </React.Fragment>
)

const Hero = ({ image }) => (
  <FullWidthWrapper
    height="110vh"
    css={`
      background-color: #1b1b1b;
    `}
  >
    <Image
      fluid={image.childImageSharp.fluid}
      css={`
        height: 100%;
      `}
      alt=""
    />
    <ParallaxWrapper
      css={`
        top: 0;
      `}
    >
      <HeroHeading />
    </ParallaxWrapper>
  </FullWidthWrapper>
)

const Services = styled('div')`
  position: relative;
  flex: 1;
`

const ServicesList = styled.ul`
  list-style: none;
  position: relative;
  @media (min-width: 1001px) {
    // max-width: 20vw;
  }
  padding: 3rem;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: #f93616;
    transform: skewY(-5.7deg);
  }
  & > li {
    margin: 1rem 0;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    font-size: 18px;
  }
`

const Package = ({
  name,
  description,
  icon: IconComponent,
  services,
  color
}) => {
  console.log(IconComponent)
  return (
    <React.Fragment>
      <AngledDivider color={color} position="top" />
      <ContentBlock
        backgroundColor={color}
        css={`
          padding-bottom: 6vw;
        `}
      >
        <FlexBox
          key={name}
          flexDirection="row"
          justifyContent="space-between"
          breakPoint="1000"
          isResponsive
        >
          <FlexBox
            flexDirection="column"
            itemSpacing="1rem"
            css={`
              @media (min-width: 1001px) {
                max-width: 40vw;
                margin-right: 5vw;
              }
            `}
          >
            <BigHeading>{name}</BigHeading>
            <Paragraph>{description}</Paragraph>
          </FlexBox>
          <Services>
            <ServicesList>
              {services.map((s, index) => (
                <li key={index}>{s}</li>
              ))}
            </ServicesList>
            <IconComponent
              key={name}
              style={{
                position: 'absolute',
                top: '-35px',
                right: '-25px',
                width: '75px'
              }}
            />
          </Services>
        </FlexBox>
      </ContentBlock>
      <AngledDivider color={color} position="bottom" />
    </React.Fragment>
  )
}

const HeroHeading = () => (
  <WordArt.Wrapper lineHeight="0.9">
    <WordArt.Line color="#f93616" fontSize="0.72em">
      If you don't have
    </WordArt.Line>
    <WordArt.Line color="#f93616" fontSize="0.63em">
      a rocket to launch
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.36em">
      launch a
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="2.04em">
      brand
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.69em">
      instead
    </WordArt.Line>
  </WordArt.Wrapper>
)

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "launch.png" }) {
      childImageSharp {
        fluid(maxWidth: 1875, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Branding
